.bordered-layout .header-navbar {
  box-shadow: none;
  border: 1px solid #ebe9f1;
}

.bordered-layout .header-navbar.floating-nav {
  border: 1px solid #ebe9f1;
}

.bordered-layout .header-navbar.fixed-top {
  background: #f8f8f8;
  border-bottom: 1px solid #ebe9f1;
  box-shadow: none !important;
}

.bordered-layout .main-menu {
  box-shadow: none;
  border-right: 1px solid #ebe9f1;
}

.bordered-layout .main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .bordered-layout .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: #ededed;
}

.bordered-layout .dropdown-menu {
  box-shadow: none;
  border: 1px solid #ebe9f1 !important;
}

.bordered-layout .main-menu .navigation, .bordered-layout .main-menu {
  background: #f8f8f8;
}

.bordered-layout .card, .bordered-layout .bs-stepper:not(.wizard-modern):not(.checkout-tab-steps), .bordered-layout .bs-stepper.wizard-modern .bs-stepper-content {
  box-shadow: none;
  border: 1px solid #ebe9f1;
}

.bordered-layout .footer {
  box-shadow: none !important;
}

.bordered-layout .footer-fixed .footer {
  border-top: 1px solid #ebe9f1;
}

/*# sourceMappingURL=index.783706a1.css.map */
